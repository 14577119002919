<template>
  <div class="language-flags">
    <div
      :class="{ 'language-flags--item__active': this.$i18n.locale === 'pt-BR' }"
      class="language-flags--item"
      @click="changeLanguage('pt-BR')"
    >
      <img :src="flagBr" alt="Brazil" />
    </div>
    <div
      :class="{ 'language-flags--item__active': this.$i18n.locale === 'en-US' }"
      class="language-flags--item"
      @click="changeLanguage('en-US')"
    >
      <img
        :src="flagEUA"
        class="language-flags--item__usa"
        alt="United States"
      />
    </div>
  </div>
</template>

<script>
/** Componente padrão de troca de idiomas */

import flagBr from '@/assets/images/flag-br.png'
import flagEUA from '@/assets/images/flag-eua.png'

import { loadLanguageAsync } from '@/plugins/i18n'

export default {
  name: 'LanguageFlags',
  data() {
    return {
      flagBr: flagBr,
      flagEUA: flagEUA
    }
  },
  methods: {
    /** Função para trocar a linguagem do site
     * @param {String} lang - Idioma a ser alterado
     */
    changeLanguage(lang = 'pt-BR') {
      this.$i18n.locale = lang
      loadLanguageAsync(lang)
    }
  }
}
</script>

<style lang="scss" scoped>
.language-flags {
  display: flex;
  gap: 15px;
  align-items: center;

  &--item {
    border-radius: 100%;
    overflow: hidden;
    width: 26px;
    height: 26px;
    position: relative;
    cursor: pointer;

    &__active {
      box-shadow: 0px 0px 0px 3px $orange;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 110%;
    }

    &__usa {
      left: 60%;
    }
  }
}
</style>
